import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getNotifications,
  readNotification,
} from "../../actions/notifications.actions";
import ImageProfil from "../Profil/ImageProfil";
import Push from "push.js";
import logoPush from "../../asset/logo-push.jpg";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

const Notification = ({ user, socket }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [readNotifications, setReadNotifications] = useState([]);
  const [previousUnreadCount, setPreviousUnreadCount] = useState(0);
  const dispatch = useDispatch();

  const notificationData = useSelector((state) => state.notificationReducer);
  const myNotification = Object.values(notificationData).filter(
    (notification) => {
      return notification;
    }
  );

  const unreadNotifications = myNotification?.filter(
    (notification) =>
      !notification.read && !readNotifications?.includes(notification.id)
  );

  useEffect(() => {
    socket?.on("getNotification", (notification) => {
      dispatch(getNotifications(notification.receiverId));
    });

    socket?.on("getNotificationPost", () => {
      const userId = user;
      dispatch(getNotifications(userId));
    });
  }, [user]);

  useEffect(() => {
    dispatch(getNotifications(user));
  }, [dispatch]);

  function extractHashtags(message) {
    const regex = /#(\w+)/g;
    const hashtags = [];
    let match;

    while ((match = regex.exec(message)) !== null) {
      hashtags.push(match[0]);
    }

    return hashtags;
  }

  //Menu toggle
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const markAsRead = (notificationId) => {
    setReadNotifications([...readNotifications, notificationId]);
    dispatch(readNotification(notificationId));
  };

  //push notification
  useEffect(() => {
    const unreadCount = unreadNotifications?.length;

    if (unreadCount > previousUnreadCount) {
      const newNotifications = unreadNotifications?.slice(previousUnreadCount);

      newNotifications.forEach((notification) => {
        if (notification?.type === "like") {
          Push.create("Nouvelle notification", {
            body: `${notification?.user?.firstname} a trouvé(e) votre post utile`,
            icon: logoPush,
            timeout: 120000,
            onClick: function () {
              window.focus();
              this.close();
            },
          });
        } else if (notification?.type === "comment") {
          Push.create("Nouvelle notification", {
            body: `${
              notification?.user?.firstname
            } a commenté(e) votre post: " ${parse(notification?.comment)}"`,
            icon: logoPush,
            timeout: 120000,
            onClick: function () {
              window.focus();
              this.close();
            },
          });
        } else if (notification?.type === "commentTuto") {
          Push.create("Nouvelle notification", {
            body: `${
              notification?.user?.firstname
            } a commenté(e) votre tuto: "${parse(notification?.comment)}"`,
            icon: logoPush,
            timeout: 120000,
            onClick: function () {
              window.focus();
              this.close();
            },
          });
        } else if (notification.type === "mention_tuto") {
          Push.create("Nouvelle notification", {
            body: `${notification?.user?.firstname} vous a mentionné(e) dans un tutoriel 👋`,
            icon: logoPush,
            timeout: 120000,
            onClick: function () {
              window.focus();
              this.close();
            },
          });
        } else if (notification.type === "mention_post") {
          Push.create("Nouvelle notification", {
            body: `${notification?.user?.firstname} vous a mentionné(e) dans un post 👋`,
            icon: logoPush,
            timeout: 120000,
            onClick: function () {
              window.focus();
              this.close();
            },
          });
        } else if (notification.type === "new_post") {
          Push.create("Nouvelle notification", {
            body: `${notification?.user?.firstname} a créé un nouveau post !`,
            icon: logoPush,
            timeout: 120000,
            onClick: function () {
              window.focus();
              this.close();
            },
          });
        } else if (notification.type === "new_tools") {
          Push.create("Nouvelle notification", {
            body: `${notification?.user?.firstname} a ajouté un nouvel outil ⚙️`,
            icon: logoPush,
            timeout: 120000,
            onClick: function () {
              window.focus();
              this.close();
            },
          });
        }
      });

      setPreviousUnreadCount(unreadCount);
    }
  }, [unreadNotifications]);

  return (
    <>
      <div className="notificaion">
        <i
          className={`far fa-bell ${
            unreadNotifications?.length > 0 ? "fa-shake" : ""
          }`}
          onClick={toggleMenu}
        ></i>
        {unreadNotifications?.length > 0 && (
          <span className="badge">{unreadNotifications?.length}</span>
        )}
      </div>
      <div className={`profil_notif_edit ${isOpen ? "active_edit_notif" : ""}`}>
        <div className="close" onClick={toggleMenu}>
          <i className="far fa-times-circle"></i>
        </div>
        <h3>Mes notifications</h3>
        <br />
        {unreadNotifications?.length > 0 ? (
          unreadNotifications?.map((notification) => (
            <div
              className="notif"
              key={notification?.id}
              onClick={() => markAsRead(notification?.id)}
            >
              {notification?.type === "like" ? (
                <div className="notif_like">
                  <Link to={`/post/${notification?.postId}`}>
                    <ImageProfil id={notification?.user?.id} />
                    <div className="notif_like_info">
                      <p>{notification?.user?.firstname} </p>
                      <span>a trouvé(e) votre post utile</span>
                    </div>
                    <p className="icon">👍</p>
                  </Link>
                </div>
              ) : notification?.type === "comment" ? (
                <div className="notif_like">
                  <Link to={`/post/${notification?.postId}`}>
                    <ImageProfil id={notification?.user?.id} />
                    <div className="notif_like_info">
                      <p>{notification?.user?.firstname} </p>
                      <span>
                        a commenté(e) votre post: "
                        {parse(notification?.comment)}"
                      </span>
                    </div>
                    <p className="icon">💬</p>
                  </Link>
                </div>
              ) : notification?.type === "commentTuto" ? (
                <div className="notif_like">
                  <Link to={`/tutoriels/${notification?.postId}`}>
                    <ImageProfil id={notification?.user?.id} />
                    <div className="notif_like_info">
                      <p>{notification?.user?.firstname} </p>
                      <span>
                        a commenté(e) votre tuto: "
                        {parse(notification?.comment)}"
                      </span>
                    </div>
                    <p className="icon">💬</p>
                  </Link>
                </div>
              ) : notification?.type === "mention_tuto" ? (
                <div className="notif_like">
                  <Link to={`/tutoriels/${notification?.postId}`}>
                    <ImageProfil id={notification?.user?.id} />
                    <div className="notif_like_info">
                      <p>{notification?.user?.firstname} </p>
                      <span>vous a mentionné(e) dans un tutoriel 👋 </span>
                    </div>
                    <p className="icon">💬</p>
                  </Link>
                </div>
              ) : notification?.type === "mention_post" ? (
                <div className="notif_like">
                  <Link to={`/post/${notification?.postId}`}>
                    <ImageProfil id={notification?.user?.id} />
                    <div className="notif_like_info">
                      <p>{notification?.user?.firstname} </p>
                      <span>vous a mentionné(e) dans un post 👋 </span>
                    </div>
                    <p className="icon">💬</p>
                  </Link>
                </div>
              ) : notification?.type === "new_post" ? (
                <div className="notif_like">
                  <Link to={`/post/${notification?.postId}`}>
                    <ImageProfil id={notification?.user?.id} />
                    <div className="notif_like_info">
                      <p>{notification?.user?.firstname} </p>
                      <span>
                        a créé un nouveau post :{" "}
                        <b>{extractHashtags(notification?.hashtag)[0]}</b>
                      </span>
                    </div>
                    <p className="icon">✉️</p>
                  </Link>
                </div>
              ) : notification.type === "new_tools" ? (
                <div className="notif_like">
                  <Link to={`/convertisseur`}>
                    <ImageProfil id={notification.user.id} />
                    <div className="notif_like_info">
                      <p>{notification.user.firstname} </p>
                      <span>a ajouté un nouvel outil à la liste</span>
                    </div>
                    <p className="icon">⚙️</p>
                  </Link>
                </div>
              ) : null}
              <i
                className="fas fa-times"
                onClick={() => markAsRead(notification.id)}
                title="Marquer comme lu!"
              ></i>
            </div>
          ))
        ) : (
          <p>Aucune notification 😴</p>
        )}
      </div>
    </>
  );
};

export default Notification;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPosts } from "../actions/post.actions";
import Card from "./Post/Card";
import { isEmpty } from "./utils/Utils";
import ImageProfil from "./Profil/ImageProfil";
import { getComments } from "../actions/comment.actions";
import { getLikes } from "../actions/likes.actions";
import { getQuiz } from "../actions/quizz.actions";
import QuizzModal from "./Quizz/QuizzModal";
import Lottie from "lottie-react";
import Quizz from "../asset/quizzz.json";
import QuizzFire from "../asset/quizFire.json";
import QuizzNew from "../asset/quizz-new.png";
import BGQUIZZ from "../asset/bg.png";
import Ballon from "../asset/ballon.json";

const Thread = ({ socket }) => {
  const usersData = useSelector((state) => state.usersReducer);
  const quizzData = useSelector((state) => state.quizFormReducer.quizzes);
  const [loadPost, setLoadPost] = useState(true);
  const [query, setQuery] = useState("");
  const [selectedQuiz, setSelectedQuiz] = useState(null);
  const [numberOfPostsToShow, setNumberOfPostsToShow] = useState(5);
  const [showAllPosts, setShowAllPosts] = useState(true);
  const [showTechPosts, setShowTechPosts] = useState(false);
  const [allPosts, setAllPosts] = useState([]);
  const [allPostsTech, setAllPostsTech] = useState([]);
  const posts = useSelector((state) => state.postReducer);
  const dispatch = useDispatch();

  const today = new Date();
  const birthdayUsers = Object.values(usersData).filter((user) => {
    const birthday = new Date(user.birthday);
    return (
      birthday.getMonth() === today.getMonth() &&
      birthday.getDate() === today.getDate()
    );
  });

  const isQuizEnCours = (quiz) => {
    const dateDebut = new Date(quiz.dateDebut);
    const dateFin = new Date(quiz.dateFin);
    const today = new Date();
    return dateDebut <= today && today <= dateFin;
  };

  useEffect(() => {
    if (loadPost) {
      dispatch(getPosts());
      dispatch(getComments());
      dispatch(getLikes());
      setLoadPost(false);
    }
  }, [loadPost, dispatch]);

  const handleShowAllPosts = () => {
    setShowAllPosts(true);
    setShowTechPosts(false);
  };

  const handleShowTechPosts = () => {
    setShowAllPosts(false);
    setShowTechPosts(true);
  };

  useEffect(() => {
    const allPostsFiltered = posts.filter(
      (post) =>
        post.texte.toLowerCase().includes(query) &&
        (!post.scheduledDate || new Date(post.scheduledDate) <= new Date())
    );

    setAllPosts(allPostsFiltered);

    const techPostsFiltered = allPostsFiltered.filter(
      (post) => post.status === 4
    );

    setAllPostsTech(techPostsFiltered);
  }, [posts, query]);

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, clientHeight, scrollHeight } =
        document.documentElement;
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        setNumberOfPostsToShow((prevValue) => prevValue + 5);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    socket?.on("getNotificationPost", () => {
      dispatch(getPosts());
    });
  }, [socket]);

  useEffect(() => {
    dispatch(getQuiz());
  }, [dispatch]);

  return (
    <>
      {/* <div className="countDownJo ">
        <iframe
          src="https://widget-859c00252b59468596f4cafeed552344.elfsig.ht"
          width="100%"
          height="60px"
          frameborder="0"
        ></iframe>
      </div> */}

      {/* <BandeauComponent /> */}

      {/* <CountdownTimer /> */}

      {birthdayUsers.length > 0 ? (
        <div className="post-container-event ">
          <div className="eventGlobal">
            {birthdayUsers.map((user, index) => (
              <div key={index} className="userInfoBirth">
                <div className="quizzFire">
                  <Lottie animationData={QuizzFire} loop={true} />
                </div>
                <div className="ballon_left">
                  <Lottie animationData={Ballon} loop={true} />
                </div>
                <div className="ballon_right">
                  <Lottie animationData={Ballon} loop={true} />
                </div>
                <div className="eventBirth">
                  <ImageProfil id={user.id} />
                </div>
                <div className="birthDateEvent">
                  <p>
                    {user.firstname} {user.name}
                  </p>
                  <h2 className="puff-in-center">
                    🎉 {birthdayUsers.length > 1 ? "Fêtent" : "Fête"}
                    {"  "} {birthdayUsers.length > 1 ? "leurs" : "ses"}
                    {"  "}
                    {birthdayUsers.map((user, index) => {
                      const birthday = new Date(user.birthday);
                      const currentYear = today.getFullYear();
                      const birthYear = birthday.getFullYear();
                      let numberOfYears = currentYear - birthYear;
                      if (
                        today.getMonth() > birthday.getMonth() ||
                        (today.getMonth() === birthday.getMonth() &&
                          today.getDate() >= birthday.getDate())
                      ) {
                        numberOfYears += 1;
                        const isBirthdayToday =
                          today.getMonth() === birthday.getMonth() &&
                          today.getDate() === birthday.getDate();
                        if (isBirthdayToday) {
                          numberOfYears -= 1;
                        }
                      }
                      return (
                        <span key={index}>
                          {numberOfYears} {numberOfYears > 1 ? "ans" : "an"}
                          {"  "}
                          {index < birthdayUsers.length - 1 ? " & " : " "}
                        </span>
                      );
                    })}{" "}
                    chez Local.fr 🎉
                  </h2>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : null}

      {quizzData &&
        Object.values(quizzData).map((quiz) => {
          if (isQuizEnCours(quiz)) {
            const dateFin = new Date(quiz.dateFin);
            const remainingTime = dateFin - new Date();
            const remainingHours = Math.floor(remainingTime / (1000 * 60 * 60));
            const remainingMinutes = Math.floor(
              (remainingTime % (1000 * 60 * 60)) / (1000 * 60)
            );
            const remainingSeconds = Math.floor(
              (remainingTime % (1000 * 60)) / 1000
            );

            return (
              <div
                key={quiz.id}
                onClick={() => setSelectedQuiz(quiz)}
                className="post-container-event-quizz "
                style={{
                  backgroundImage: `url(${BGQUIZZ})`,
                  backgroundSize: "cover",
                  backgroundPosition: "bottom -113px right 0px",
                }}
              >
                <div className="quizzImgNew">
                  <img src={QuizzNew} alt="" />
                </div>
                <div className="quizz">
                  <Lottie animationData={Quizz} loop={true} />
                </div>
                <div className="quizzFire">
                  <Lottie animationData={QuizzFire} loop={true} />
                </div>
                <div className="quizzContent">
                  <div className="quizzTexte">Un quizz est en cours !</div>
                  <div className="quizzChrono">
                    Temps restant : {remainingHours}h {remainingMinutes}m{" "}
                    {remainingSeconds}s
                  </div>
                  <button className="url_site">
                    <span className="button-content">Participer</span>
                  </button>
                </div>
              </div>
            );
          }
          return null;
        })}

      <div className="post-container">
        <div className="search_post">
          <div className="search-icon-container">
            <i className="fa-solid fa-magnifying-glass search-icon"></i>
          </div>
          <input
            type="text"
            placeholder=" Rechercher un post"
            className="searchbar_post"
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>
      </div>
      <div className="thread-container">
        <div className="tabPost">
          <div
            className={`allPost ${showAllPosts ? "active" : ""}`}
            onClick={handleShowAllPosts}
          >
            Tous les posts
          </div>
          <div
            className={`techPost ${showTechPosts ? "active" : ""}`}
            onClick={handleShowTechPosts}
          >
            Posts techniques
          </div>
        </div>
        <div>
          {!isEmpty(posts[0]) &&
            (showAllPosts ? allPosts : allPostsTech)
              .slice(0, numberOfPostsToShow)
              .map((post) => {
                return <Card post={post} key={post.id} socket={socket} />;
              })}
        </div>
      </div>
      {selectedQuiz && (
        <QuizzModal quiz={selectedQuiz} onClose={() => setSelectedQuiz(null)} />
      )}
    </>
  );
};

export default Thread;
